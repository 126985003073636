import * as React from "react"

import FooterLayout from "../components/footer"
import Menu from "../components/home"

export default function Terms() {
    return (
        <Menu>
            <FooterLayout>
                <h1>Terms and Conditions</h1>
                <div> 
                    <div>
                        <h3>1. Introduction</h3>
                        <p>Hello, and welcome to the Geognosis Terms and Conditions of Use (“Terms”).</p>
                        <p>Thanks for choosing Geognosis App, an educational geography app created by Christos Karaiskos ("me", "Geognosis", "we", "us", "our"). Use of the Geognosis App and any of its content, services and features (collectively "Services"), as defined below, is subject to your acceptance of these terms.</p>
                    </div>
                    <div>
                        <h3>2. Changes to Terms</h3>
                        <p>We are constantly looking for ways to improve and expand Geognosis. We may update these terms periodically, as the Geognosis app evolves. The amended terms shall be effective upon posting. Any use after amendment constitutes acceptance of the new terms. The updated Terms and Conditions are always available here (at <a href="https://geognosis.app/terms">geognosis.app/terms</a>).
                        Thanks for choosing Geognosis App, an educational geography app created by Christos Karaiskos ("me", "Geognosis", "we", "us", "our"). Use of the Geognosis App and any of its content, services and features (collectively "Services"), as defined below, is subject to your acceptance of these terms.</p>
                        <p>If you do not agree to these terms and conditions including our privacy policy and you are not willing to be bound by them, do not install our application and/or promptly erase it and any part thereof, from your mobile phone and do not use it in any manner whatsoever.</p>
                    </div>
                    <div>
                        <h3>3. License</h3>
                        <p>Geognosis grants you a revocable, non-exclusive, non-transferable, limited license to download, install and use the Application solely for your personal, non-commercial purposes strictly in accordance with the terms of this Agreement. You agree not to, and you will not permit others to:</p>
                        <ul>
                            <li>license, sell, rent, lease, assign, distribute, transmit, host, outsource, disclose or otherwise commercially exploit the Application</li>
                            <li>modify, make derivative works of, disassemble, decrypt, reverse compile or reverse engineer any part of the Application</li>
                            <li>remove, alter or obscure any proprietary notice (including any notice of copyright or trademark) of Geognosis or its affiliates, partners, suppliers or the licensors of the Application.</li>
                        </ul>
                    </div>
                    <div>
                        <h3>4. Third-party Services</h3>
                        <p>Our Service may contain links to third-party web sites or services that are not owned or controlled by us.</p>
                        <p>You acknowledge and agree that Geognosis shall not be responsible for any Third-Party Services, including their accuracy, completeness, timeliness, validity, copyright compliance, legality, decency, quality or any other aspect thereof. Geognosis does not assume and shall not have any liability or responsibility to you or any other person or entity for any Third-Party Services.</p>
                        <p>Third-Party Services and links thereto are provided solely as a convenience to you and you access and use them entirely at your own risk and subject to such third parties’ terms and conditions.</p>
                    </div>
                    <div>
                        <h3>5. Limitation of Liability</h3>
                        <p>We do our best to provide Services to you on a 24-hour basis, but cannot guarantee that our services will be available to you at all times. We provide our services on an “as is” basis. We cannot provide any type of guarantee nor are we responsible for the correctness, validity and accuracy of any information published in the Geognosis App.</p>
                        <p>We offer no guarantee that the Services, functions and content of the App will be provided without interruption or without errors or that the Geognosis App or the servers through which it becomes available to you do not contain viruses or other harmful components, although we make every effort to ensure it.</p>
                    </div>
                    <div>
                        <h3>6. Trademarks and Intellectual Property rights</h3>
                        <p>All of the content featured or displayed in the Geognosis App and all marks or elements of our Services, including without limitation to text, graphics, images and illustrations, source code, databases, software, designs, and all and any registered trademark and trade name of Geognosis used herein, are owned by Christos Karaiskos and are protected by copyright, trademark, moral rights and other laws relating to intellectual property rights. You may not copy, reproduce, republish, furnish, create derivatives of, transmit, display, post, upload, modify, distribute or in any other way use or exploit Geognosis's trademarks or copyrights or other intellectual property rights, without Geognosis's prior written consent. You shall not copy or remove any trademark, copyright or other proprietary notices from the App or our Services and features.</p>
                        <p>You expressly understand and acknowledge that the trade name of Geognosis, registered trademarks, copyrights and any intellectual property regarding the App and our Services shall all remain the exclusive property of Christos Karaiskos.</p>
                    </div>
                    <div>
                        <h3>7. Updates</h3>
                        <p>Updates may modify or delete certain features and/or functionalities of the Geognosis App. You agree that Geognosis has no obligation to (i) provide any Updates, or (ii) continue to provide or enable any particular features and/or functionalities of the Geognosis App to you.</p>
                    </div>
                    <div>
                        <h3>8. Suggestions</h3>
                        <p>Any feedback, comments, ideas, improvements or suggestions (collectively, “Suggestions”) provided by you to Geognosis with respect to the Geognosis App shall remain the sole and exclusive property of Geognosis.</p>
                        <p>Geognosis shall be free to use, copy, modify, publish, or redistribute the Suggestions for any purpose and in any way without any credit or any compensation to you.</p>
                    </div>
                    <div>
                        <h3>9. Personal Data processing – Privacy Policy</h3>
                        <p>Your privacy is very important to us. For more information about how we collect and process your personal data please read our <a href="/privacy-policy">Privacy Policy</a> which is a part of this Agreement.</p>
                    </div>
                    <div>
                        <h3>10. Contact</h3>
                        <p>If you have any questions about this Agreement, please <a href="/contact">contact us</a>.​</p>
                    </div>
                </div>
            </FooterLayout>
      </Menu>
    );
  }